import React from "react";
import { Wrapper, Container, Row, Spacer, Slide } from "./styles";
import { Notification } from "src/hooks/notifications/models/notification.model";
import NotificationCard from "../card";
import { useNotificationCenterStore } from "src/store/notificationCenterStore";
import { fadeInRight } from "./variants";
import { AnimatePresence } from "framer-motion";

export default function NotificationToast() {
  const { toasty } = useNotificationCenterStore();
  return (
    <Wrapper>
      <Container>
        <AnimatePresence presenceAffectsLayout>
          {React.Children.toArray(
            toasty.map((n: Notification, index: number) => (
              <React.Fragment key={`notification-row-${n?.id}`}>
                {index > 0 && <Spacer key={`spacer-${index}`} />}
                <Slide
                  key={`notification-${n?.id}`}
                  variants={fadeInRight}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  layout
                  layoutId={n?.id}
                >
                  <Row>
                    <NotificationCard
                      notification={n}
                      type="toast"
                      key={`notification-row-${n?.id}`}
                    />
                  </Row>
                </Slide>
              </React.Fragment>
            ))
          )}
        </AnimatePresence>
      </Container>
    </Wrapper>
  );
}
