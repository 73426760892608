import ShortUniqueId from "short-unique-id";
/**
 * Return a short UUID based on default dictionary
 *
 * @param {number} length size of the UUID.
 * @return {string} the UUID generated.
 */
export function GenerateShortUUID(length?: number): string {
  const uid = new ShortUniqueId({
    dictionary: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "7",
      "8",
      "9",
      "Z",
      "F",
      "C",
      "G",
      "H",
      "W",
    ],
    length: length ?? 5,
  });
  return uid();
}

/**
 * Return a short UUID based on default dictionary
 *
 * @param {number} length size of the UUID.
 * @return {string} the UUID generated.
 */
export function GenerateAlphabeticalShortUUID(length?: number): string {
  const uid = new ShortUniqueId({
    dictionary: [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
    ],
    length: length ?? 5,
  });
  return uid();
}

/**
 * Retorna um UUID curto com base no dicionário padrão, contendo letras e números.
 *
 * @param {number} length Tamanho do UUID.
 * @return {string} O UUID gerado.
 */
export function GenerateAlphanumericShortUUID(length?: number): string {
  const uid = new ShortUniqueId({
    dictionary: [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ],
    length: length ?? 32,
  });
  return uid();
}
