import { styled, Skeleton as MuiSkeleton } from "@sync/core";

const Skeleton = styled(MuiSkeleton)`
  transform: unset;
`;

export const BoxSkeleton = styled(Skeleton)`
  width: 56px;
  min-width: 56px;
  height: 56px;
  border-radius: 8px;
`;

export const TitleSkeleton = styled(Skeleton)`
  width: 100px;
  height: 14px;
  border-radius: 2px;
`;

export const DescriptionSkeleton = styled(Skeleton)`
  width: 210px;
  height: 12px;
  border-radius: 2px;
  margin-top: 5px;
`;
