import { PropsWithChildren, useEffect } from "react";
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from "@mui/material";

import { hasCookie, getCookie } from "cookies-next";
import Head from "next/head";
import { ThemeTypes } from "src/store/themeStore/props";
import { useThemeStore } from "src/store/themeStore";
import { themeConfig } from "src/styles/theme";

const CustomThemeProvider: React.FC<PropsWithChildren> = ({
  children,
}: PropsWithChildren) => {
  const { setTheme, theme } = useThemeStore();
  const appliedTheme =
    themeConfig[theme == ThemeTypes.Light ? ThemeTypes.Light : ThemeTypes.Dark];

  function loadTheme() {
    function getTheme() {
      if (hasCookie("@sync-theme")) {
        return getCookie("@sync-theme") == "light"
          ? ThemeTypes.Light
          : ThemeTypes.Dark;
      }

      return ThemeTypes.Dark;
    }

    const defaultTheme: ThemeTypes = getTheme();

    if (defaultTheme != ThemeTypes.Dark) {
      setTheme(ThemeTypes.Light);
    }
  }

  useEffect(loadTheme, []);

  return (
    <>
      <Head>
        <meta
          name="theme-color"
          content={appliedTheme.palette.background.default}
        />
      </Head>

      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={{ theme: appliedTheme }}>
          {children}
        </MuiThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export default CustomThemeProvider;
