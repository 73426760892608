import { LazyMotion } from "framer-motion";
import { ReactNode } from "react";

// ----------------------------------------------------------------------

// eslint-disable-next-line import/extensions
const loadFeatures = () =>
  import("./features.js" as string).then((res) => res.default);

type Props = {
  children: ReactNode;
};

export default function MotionLazyContainer({ children }: Props) {
  return (
    <LazyMotion strict features={loadFeatures}>
      {children}
    </LazyMotion>
  );
}
