/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AdvertiseStatus {
  PUBLISHED = "Published",
  ERROR = "Error",
  LOADING = "Loading",
  NOT_PUBLISHED = "NotPublished",
}
