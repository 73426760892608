export enum NotificationType {
  Contact = "Contact",
  Publish = "Publish",
  Unpublish = "Unpublish",
  UpdateVehicle = "UpdateVehicle",
  UpdateVehicleStatus = "UpdateVehicleStatus",
  Contract = "Contract",
  VehicleSale = "VehicleSale",
  FinancialMovement = "FinancialMovement",
  LiveEditing = "LiveEditing",
  Credetials = "Credentials",
  PaymentSuccess = "PaymentSuccess",
  PaymentFailed = "PaymentFailed",
  NextExpiration = "NextExpiration",
  ServiceSuspension = "ServiceSuspension",
  NewContact = "NewContact",
  AssistantConfig = "AssistantConfig",
  AssistantChanged = "AssistantChanged",
  ConnectionSuccess = "ConnectionSuccess",
  ConnectionFailed = "ConnectionFailed",
  NewVehicleRegistered = "NewVehicleRegistered",
  VehicleAcquired = "VehicleAcquired",
  Success = "Success",
  Info = "Info",
  Error = "Error",
}
