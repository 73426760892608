import { alpha, styled } from "@sync/core";
import { m as motion } from "framer-motion";

export const Wrapper = styled("div")`
  position: fixed;
  right: 20px;
  bottom: calc(5vh - 5px);
  z-index: 47;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
`;

export const Container = styled("div")`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
`;

export const Spacer = styled("div")`
  margin-top: 10px;
`;

export const Row = styled("div")`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background-color: ${({ theme }) =>
    alpha(theme.palette.background.default, 0.5)};
  min-width: 200px;
  max-width: 350px;
  width: 100%;
`;

export const Slide = styled(motion.div)`
  position: relative;
  z-index: 9999999;
`;
