import { BoxSkeleton, TitleSkeleton, DescriptionSkeleton } from "./styles";
import { Container } from "../styles";
import { Wrapper } from "../renderer/styles";

export default function NotificationCardSkeleton() {
  return (
    <Container type="row">
      <BoxSkeleton />
      <Wrapper type={"row"} notification={{} as any}>
        <TitleSkeleton />
        <DescriptionSkeleton />
        <DescriptionSkeleton />
      </Wrapper>
    </Container>
  );
}
