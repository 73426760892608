import { useMemo } from "react";
import { RendererProps } from "../props";
import useTimeAgo from "src/hooks/useTimeAgo";
import { Box, Icon, Wrapper } from "./styles";
import { getIcon } from "./helper";

export default function Renderer({
  notification,
  type,
}: RendererProps): JSX.Element {
  const timeAgo = useTimeAgo(new Date(notification?.createdAt ?? ""));

  const renderContent = useMemo(
    () => (
      <>
        <Box type={notification?.status}>
          <Icon
            type={notification?.type}
            icon={getIcon(notification?.type) as string}
          />
        </Box>
        <Wrapper type={type} notification={notification}>
          <div className="title">{notification?.service}</div>
          <div className="description">{notification?.description}</div>
          <div className="time-ago">{timeAgo}</div>
        </Wrapper>
      </>
    ),
    [notification?.id, timeAgo]
  );

  return renderContent;
}
