export const fadeInRight = {
  hidden: {
    opacity: 0,
    right: "-100%",
  },
  visible: {
    opacity: 1,
    right: "0%",
    transition: {
      type: "spring",
    },
  },
};
