/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AdvertiseState {
  UNDEFINED = "Undefined",
  INVENTORY = "Inventory",
  DELETED = "Deleted",
  TRANSITO = "Transito",
  SOLD = "Sold",
}
