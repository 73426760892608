export enum ThemeTypes {
  Light = "light",
  Dark = "dark",
}

export type ThemeState = {
  theme: ThemeTypes;
};

export interface ThemeActions {
  setTheme: (theme: ThemeTypes) => void;
}
