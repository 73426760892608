import { create } from "zustand";

import { NotificationCenterActions, NotificationCenterState } from "./props";
import { queryClient } from "src/pages/_app";
import { NotificationQueryKeys } from "src/hooks/notifications/queryKeys";
import { Notification } from "src/hooks/notifications/models/notification.model";
import { Severity } from "src/hooks/notifications/enum/Severity.enum";
import { GenerateShortUUID } from "@sync/utils";

export const useNotificationCenterStore = create<
  NotificationCenterState & NotificationCenterActions
>((set) => ({
  toasty: [],
  addNotification(n) {
    queryClient.invalidateQueries([NotificationQueryKeys.UnseenCount]);
    set((state) => ({
      ...state,
      toasty: state.toasty.concat(n),
    }));
  },
  removeNotification(id) {
    set((state) => ({
      ...state,
      toasty: state.toasty.filter((notification) => notification.id != id),
    }));
  },
  addToastNotification({ variant, description, title }) {
    function getTitle(): string {
      if (title) {
        return title;
      }

      switch (variant) {
        case "Error":
          return "Ops!";
        case "Success":
          return "Tudo certo!";
        case "Info":
          return "Importante:";
      }
    }
    function getDescription(): string {
      if (description) {
        return description;
      }

      switch (variant) {
        case "Error":
          return "Por favor, tente novamente mais tarde.";
        case "Success":
          return "Tudo certo com sua solicitação.";
        case "Info":
          return "";
      }
    }

    set((state) => ({
      ...state,
      toasty: state.toasty.concat({
        id: GenerateShortUUID(6),
        createdAt: String(new Date()),
        general: false,
        seen: false,
        service: getTitle(),
        severity: Severity.Low,
        type: variant,
        status: variant,
        description: getDescription(),
      } as Notification),
    }));
  },
}));
