import { NotificationType } from "src/hooks/notifications/enum/NotificationType.enum";

export function getIcon(type: NotificationType) {
  switch (type) {
    case NotificationType.Contact:
    case NotificationType.NewContact:
      return "ic:baseline-contact-mail";
    case NotificationType.Publish:
    case NotificationType.NewVehicleRegistered:
      return "material-symbols:car-tag";
    case NotificationType.Unpublish:
      return "pepicons-pop:car-off";
    case NotificationType.UpdateVehicle:
    case NotificationType.LiveEditing:
      return "fa6-solid:car-on";
    case NotificationType.UpdateVehicleStatus:
      return "ic:round-car-crash";
    case NotificationType.Contract:
      return `material-symbols-light:contract-outline`;
    case NotificationType.VehicleSale:
      return "mdi:printer-point-of-sale-check";
    case NotificationType.PaymentSuccess:
      return `mdi:cash-check`;
    case NotificationType.PaymentFailed:
      return `mdi:cash-remove`;
    case NotificationType.NextExpiration:
      return `mdi:cash-clock`;
    case NotificationType.ServiceSuspension:
      return `ooui:un-block`;
    case NotificationType.AssistantConfig:
    case NotificationType.AssistantChanged:
      return `fluent:bot-sparkle-24-filled`;
    case NotificationType.ConnectionSuccess:
      return `mdi:robot-happy`;
    case NotificationType.ConnectionFailed:
      return `mdi:robot-off`;
    case NotificationType.VehicleAcquired:
      return `mdi:car-key`;
    case NotificationType.Error:
      return "bxs:error";
    case NotificationType.Info:
      return "mingcute:information-fill";
    case NotificationType.Success:
      return "ep:success-filled";
  }
}
