import { Iconify, alpha, styled } from "@sync/core";
import { RendererProps } from "../props";
import { BoxProps, IconProps } from "./props";
import { NotificationType } from "src/hooks/notifications/enum/NotificationType.enum";
import { NotificationStatus } from "src/hooks/notifications/enum/notification-status.enum";

export const Box = styled("div")<BoxProps>`
  width: 56px;
  min-width: 56px;
  height: 56px;
  border-radius: 8px;
  background-color: ${({ theme, type }) => {
    switch (type) {
      case NotificationStatus.Success:
        return alpha(theme.palette.success.main, 0.2);
      case NotificationStatus.Error:
        return alpha(theme.palette.error.main, 0.2);
      case NotificationStatus.Info:
        return alpha(theme.palette.primary.main, 0.2);
      default:
        return alpha(theme.palette.primary.main, 0.2);
    }
  }};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled("div")<RendererProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  line-height: 1rem;
  gap: 4px;

  .title {
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme }) => theme.palette.text.primary};
  }

  .description {
    width: ${({ type }) => (type == "row" ? "100%" : "calc(100% - 30px)")};
    font-size: 14px;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .time-ago {
    width: 100%;
    font-size: 10px;
    color: ${({ theme }) => theme.palette.text.secondary};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

export const Icon = styled(Iconify)<IconProps>`
  width: 28px;
  height: 28px;
  color: ${({ theme, type }) => {
    switch (type) {
      case NotificationType.Success:
      case NotificationType.AssistantChanged:
      case NotificationType.AssistantConfig:
      case NotificationType.ConnectionSuccess:
      case NotificationType.LiveEditing:
      case NotificationType.NewVehicleRegistered:
      case NotificationType.PaymentSuccess:
      case NotificationType.VehicleSale:
      case NotificationType.Publish:
      case NotificationType.VehicleAcquired:
      case NotificationType.Unpublish:
        return theme.palette.success.main;
      case NotificationType.ServiceSuspension:
      case NotificationType.PaymentFailed:
      case NotificationType.Error:
      case NotificationType.ConnectionFailed:
        return theme.palette.error.main;
      case NotificationType.Info:
      case NotificationType.UpdateVehicle:
      case NotificationType.UpdateVehicleStatus:
      case NotificationType.Contact:
      case NotificationType.Contract:
      case NotificationType.Credetials:
      case NotificationType.NewContact:
      case NotificationType.FinancialMovement:
      case NotificationType.NextExpiration:
        return theme.palette.primary.main;
      default:
        return theme.palette.text.primary;
    }
  }};
`;
