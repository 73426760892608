import { create } from "zustand";

import { ThemeState, ThemeTypes, ThemeActions } from "./props";
import { getCookie } from "cookies-next";

export const useThemeStore = create<ThemeState & ThemeActions>((set) => ({
  theme: (getCookie("@sync-theme") as ThemeTypes) ?? ThemeTypes.Light,
  setTheme(theme) {
    set({
      theme,
    });
  },
}));
