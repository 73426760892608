import { NotificationCardProps } from "./props";
import { CircularProgressBarContainer, CloseIcon, Container } from "./styles";
import { useNotificationCenterStore } from "src/store/notificationCenterStore";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useCountUp } from "use-count-up";
import { memo, useEffect } from "react";
import NotificationCardSkeleton from "./skeleton";
import Renderer from "./renderer";

function NotificationCard({
  notification,
  type,
  isLoading = false,
}: NotificationCardProps): JSX.Element {
  const { removeNotification } = useNotificationCenterStore();

  const { value } = useCountUp({
    start: new Date(notification?.createdAt).getTime(),
    end: new Date(notification?.createdAt).getTime() - 10000,
    duration: 10,
    updateInterval: 1,
    easing: "linear",
    isCounting: type == "toast",
  });

  function handleRemoveNotification() {
    removeNotification(notification.id);
  }

  function handleAutomateRemoveNotification() {
    if (type == "toast") {
      setTimeout(() => {
        removeNotification(notification.id);
      }, 10000);
    }
  }

  useEffect(handleAutomateRemoveNotification, []);

  if (isLoading) {
    return <NotificationCardSkeleton />;
  }

  return (
    <Container type={type}>
      <Renderer type={type} notification={notification} />
      {type == "toast" && (
        <>
          <CloseIcon
            onClick={handleRemoveNotification}
            icon="carbon:close-filled"
          />
          <CircularProgressBarContainer>
            <CircularProgressbar
              value={value as number}
              minValue={new Date(notification?.createdAt).getTime() - 10000}
              maxValue={new Date(notification?.createdAt).getTime()}
              key={`notification-${notification?.id}`}
              styles={buildStyles({ pathTransition: "1.7s" })}
              strokeWidth={15}
            />
          </CircularProgressBarContainer>
        </>
      )}
    </Container>
  );
}

export default memo(NotificationCard);
