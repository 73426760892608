import { useEffect, useState } from "react";

const useTimeAgo = (date: Date) => {
  const [timeAgo, setTimeAgo] = useState<string>("");

  useEffect(() => {
    const calculateTimeAgo = () => {
      const now = new Date();
      const diffInMilliseconds = now.getTime() - date.getTime();

      const seconds = Math.floor(diffInMilliseconds / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);

      if (seconds < 60) {
        setTimeAgo("há alguns segundos atrás");
      } else if (minutes < 60) {
        setTimeAgo(
          `há ${minutes} ${minutes === 1 ? "minuto" : "minutos"} atrás`
        );
      } else if (hours < 24) {
        setTimeAgo(`há ${hours} ${hours === 1 ? "hora" : "horas"} atrás`);
      } else {
        const formattedDate = `${String(date.getDate()).padStart(
          2,
          "0"
        )}/${String(date.getMonth() + 1).padStart(2, "0")} ${String(
          date.getHours()
        ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;
        setTimeAgo(formattedDate);
      }
    };

    calculateTimeAgo();

    const intervalId = setInterval(calculateTimeAgo, 60 * 1000);

    return () => clearInterval(intervalId);
  }, [date]);

  return timeAgo;
};

export default useTimeAgo;
