import { Iconify, alpha, styled } from "@sync/core";
import { ContainerProps } from "./props";

export const Container = styled("div")<ContainerProps>`
  width: 100%;
  min-width: 320px;
  display: flex;
  position: relative;
  align-items: center;
  gap: 16px;
  background-color: ${({ type, theme }) => {
    switch (type) {
      case "toast":
        return theme.palette.background.default;
      case "row":
        return "transparent";
    }
  }};
  border-radius: ${({ type }) => (type == "row" ? "0px" : "16px")};
  padding: ${({ type }) => (type == "row" ? "16px 0px" : "16px")};
  border: 1px solid
    ${({ type, theme }) =>
      type == "toast"
        ? alpha(theme.palette.text.secondary, 0.2)
        : "transparent"};

  ${({ type, theme }) =>
    type == "row" &&
    `
    border-bottom: 1px solid ${alpha(theme.palette.text.secondary, 0.2)};
  `}
`;

export const CloseIcon = styled(Iconify)`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.text.secondary};
  position: absolute;
  right: 4px;
  top: 4px;
  transition: color 0.3s, background 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.palette.background.neutral};
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

export const CircularProgressBarContainer = styled("div")`
  width: 100%;
  max-width: 20px;
  margin-left: 8px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);

  & .CircularProgressbar-path {
    stroke: ${({ theme }) => theme.palette.primary.main};
    transition: all 1s;
  }

  & .CircularProgressbar-trail {
    stroke: ${({ theme }) => theme.palette.background.default};
  }
`;
